<template>
  <div v-loading="form.isLoading">
    <div class="has-text-white">
      <heading size="4" color="white" :serif="false">
        {{ $t('pages.password_recovery.title') }}
      </heading>
      <h2>{{ $t('pages.password_recovery.subtitle') }}</h2>

      <form
        class="m-t-l"
        @submit.prevent="submit"
      >
        <form-field :validator="$v.form.email" :attribute="$t('forms.email')">
          <input
            v-model="form.email"
            :placeholder="$t('forms.your_email')"
            class="input"
            type="email"
            name="email"
          >
        </form-field>

        <div class="links">
          <v-button type="submit" class="is-black is-large m-b-m">
            {{ $t('forms.send') }}
          </v-button>
          <router-link :to="{ name: 'login' }" class="has-text-white has-text-centered has-text-decoration-underline m-b-m">
            {{ $t('pages.password_recovery.back_to_login') }}
          </router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import Form from '@/services/forms/Form'

export default {
  data: () => ({
    form: new Form({
      email: { value: '', rules: { required, email } }
    })
  }),

  validations () {
    return {
      form: this.form.rules()
    }
  },

  methods: {
    submit () {
      this.$v.form.$touch()

      if (this.$v.form.$error) {
        return
      }

      this.form.post('/passwords/forgot')
        .then(data => {
          this.$notify({
            title: this.$t('general.email_sent'),
            message: this.$t('success.password_reset_link_sent'),
            type: 'success'
          })

          this.$router.push({ name: 'login' })
        })
        .catch(error => {
          this.$displayRequestError(error, this.$t('pages.password_recovery.unable_to_reset_password'))
          this.$router.push({ name: 'login' })
        })
    }
  }
}
</script>
